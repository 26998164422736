import {css} from "styled-components"
export const Responsiveness = (props, sporp, trop)=>{
return css`
@media only screen and (max-width: 380px){
    ${props}
}

@media only screen and ((min-width: 381px) and (max-width: 415px)){
    ${sporp}
}

@media only screen and ((min-width: 415px) and (max-width: 680px)){
    ${trop}
}
`
}
