import React, { useContext } from "react";
import { LanguageContext } from "../../contexts/Language/LanguageContext";
import { styled } from "styled-components";
import { Responsiveness } from "../../responsive";
import { SocialIcon } from "react-social-icons";

const Container = styled.div`
  background-color: rgb(41, 46, 51);
`;
const Wrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Responsiveness({ padding: "5px 5px" }, { padding: "5px 10px" })};
`;

const Left = styled.div`
  flex: 1.5;
  display: flex;
  flex-direction: column;
  color: white;
`;

// const Center = styled.div`
// flex:1;
// text-align: center;
// color:white;
// `

const Right = styled.div`
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${Responsiveness(
    { flexDirection: "column", justifyContent: "center" },
    { flexDirection: "column", justifyContent: "center" }
  )}
`;

const Text = styled.h5`
  text-align: left;
  margin-left: 1rem;
  margin-bottom: 0.25rem;
  font-size: 22px;
  ${Responsiveness(
    { fontSize: "12px" },
    { fontSize: "13px" },
    { fontSize: "15px" }
  )}
`;
const Title = styled.h4`
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  ${Responsiveness(
    { fontSize: "13px", marginLeft: "0.5rem" },
    { fontSize: "14px", marginLeft: "0.5rem" },
    { fontSize: "16px", marginLeft: "0.5rem" }
  )}
`;

const CustomSocialIcon = styled(SocialIcon)`
  width: 50px;
  height: 50px;
  margin-top: 1rem;
  margin-left: 0.5rem;

  ${Responsiveness(
    { width: "15px", height: "15px", marginTop: "0.5rem" },
    { width: "25px", height: "25px", marginTop: "0.5rem" },
    { width: "25px", height: "25px", marginTop: "0.5rem" }
  )};
`;
const Footer = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);
  let data;
  switch (language) {
    case "Deutsch":
      data = {
        text: "Impressum",
        address: "Anschrift",
        country: "Deutschland",
      };
      break;
    default:
      data = {
        text: "Imprint",
        address: "Address",
        country: "Germany",
      };
      break;
  }

  return (
    <Container>
      <Wrapper>
        <Left>
          <Title>{data.text}:</Title>
          <Text>NB-Industries e.K.</Text>
          <Text>+49 6021 326 8139</Text>
          <Text>info@nb-industries.com</Text>
          <Text>Bodelschwinghstraße 14,</Text>
          <Text>63739 Aschaffenburg</Text>
          <Text>Bayern, {data.country}</Text>
        </Left>
        {/* <Center>
        </Center> */}
        <Right>
          <CustomSocialIcon
            url="https://www.instagram.com/nb_industries_aburg/"
            network="instagram"
          />
          <CustomSocialIcon
            url="https://twitter.com/IndustriesNb"
            network="twitter"
          />
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Footer;
