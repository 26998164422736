import React, { useContext } from 'react'
import { LanguageContext } from '../../contexts/Language/LanguageContext';
import { styled } from 'styled-components';
import { Responsiveness } from '../../responsive';
const Container = styled.div`
background-color: #afcdea;
`

const Title = styled.h1`
font-size: 32px;
margin: 20px 0px;
text-align: center;
font-weight: 600;
${Responsiveness({fontWeight:"200", fontSize:"12px"},{fontWeight:"250", fontSize:"19px"})}
`

const Text = styled.h2`
text-align: left;
margin:20px 10px;
font-size: 26px;
font-weight: 400;
${Responsiveness({fontWeight:"100", fontSize:"10px", margin:"0px 4px"},{fontWeight:"150", fontSize:"17px", margin:"5px 8px"})}
`

const Checker = styled.div`
display: grid;
  grid-template-columns: repeat(2, 1fr); 
  ${Responsiveness({gridTemplateColumns:"repeat(2, 1fr)"},{gridTemplateColumns:"repeat(2, 1fr)"})}
`

const CheckerItem = styled.div`
  display: flex;
  flex-direction: column;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;
const Experties = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);
  let data;
  switch (language) {
    case "Deutsch":
      data={
        title1:"Unsere Erfahrungen in vielfältige Projekte ist das was uns ausmacht",
        text1: "Durch unsere Experten und deren Erfahrung in den unterschiedlichsten Projekten sowie in den verschiedensten Branchen haben wir ein perfektes Team zusammen gestellt, welches Kompetenz mit Verantwortung verknüpf hat. Unser Familienunternehmen bringt umfassenden Service mit einzigartigem Engagement an den tag, worauf wir sehr Stolz sind.",
        title2: "Was uns ausmacht?",
        text21: "✔ Kundenservice ist das A und O für uns",
        text22: "✔ Fachkenntnis und Erfahrung",
        text23: "✔ Globaler Export sowie Import",
        text24: "✔ Dieverse Maschinen passend für Ihre Bedürfnisse",
        text25: "✔ Reperatur und Beratung",
        text26: "✔ Standorte in den Nordamerika, Europa und Asien",
      }
      break;
    default:
      data={
        title1:"Our experience in diverse projects is what sets us apart",
        text1: "Thanks to our experts and their experience in a wide variety of projects and in a wide variety of industries, we have put together a perfect team that combines competence with responsibility. Our family business brings comprehensive service with unique commitment to the day, which we are very proud of.",
        title2: "What defines us?",
        text21: "✔ Customer service is the be-all and end-all for us",
        text22: "✔ Expertise and experience",
        text23: "✔ Global export and import",
        text24: "✔ Various machines suitable for your needs",
        text25: "✔ Repair and advice",
        text26: "✔ Locations in North America, Europe and Asia",
      }
      break;
  }
  return (
    <Container>
        <Checker>
            <CheckerItem>
                <Title>{data.title1}</Title>
                <Text>{data.text1}</Text>
            </CheckerItem>
            <CheckerItem>
                <Image src='tarash.jpg'/>
            </CheckerItem>
            <CheckerItem>
                <Image src='cog.jpg'/>
            </CheckerItem>
            <CheckerItem>
                <Title>{data.title2}</Title>
                <Text>{data.text21}</Text>
                <Text>{data.text22}</Text>
                <Text>{data.text23}</Text>
                <Text>{data.text24}</Text>
                <Text>{data.text25}</Text>
                <Text>{data.text26}</Text>

            </CheckerItem>
        </Checker>
    </Container>
  )
}

export default Experties