import * as React from "react"
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import { styled } from "styled-components";
import { LanguageContext } from "../../contexts/Language/LanguageContext";
const Container = styled.div`
height: 100vh;
min-height: 100vh;
display: flex;
flex-direction: column;
`
const Existance = styled.div`
flex: 1;
display: flex;
justify-content: center;
align-items center;
`

const NotFoundPage = () => {
  const { language } = React.useContext(LanguageContext);
  let data;
  switch (language) {
    case "Deutsch":
      data={
        existing:"WAS DU WILLST, EXISTIERT NICHT :)",
      }
      break;
    default:
      data={
        existing:"WHAT YOU WANT DOESN'T EXIST :)"
      }
      break;
  }
    return (
    <Container>
      <Helmet>
                <title>Not Found</title>
                <meta name="description" content="The Link Is Invalid." />
                <meta name="robots" content="noindex,nofollow" />
                <link rel="canonical" href="https://www.nb-industries.com/" />

        </Helmet>
        <Navbar />
        <Existance>

          {data.existing}
        </Existance>        
      <Footer />
    </Container>);
}   

export default NotFoundPage;