import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import NotFoundPage from "./pages/NotFound/404";
import styled from "styled-components";
import Gallery from "./pages/Gallery/Gallery";
import Contact from "./pages/Contact/Contact";

const Routing = styled.div``;
const Container = styled.div``;
function getRandomHeight(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function App() {
  const images = [
    { src: "/gallery/1.png", height: getRandomHeight(200, 400), alt: "" },
    { src: "/gallery/2.png", height: getRandomHeight(300, 500), alt: "" },
    { src: "/gallery/3.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/4.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/5.png", height: getRandomHeight(300, 500), alt: "" },
    { src: "/gallery/6.png", height: getRandomHeight(200, 400), alt: "" },
    { src: "/gallery/7.png", height: getRandomHeight(200, 400), alt: "" },
    { src: "/gallery/8.png", height: getRandomHeight(300, 500), alt: "" },
    { src: "/gallery/9.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/10.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/11.png", height: getRandomHeight(300, 500), alt: "" },
    { src: "/gallery/12.png", height: getRandomHeight(200, 400), alt: "" },
    { src: "/gallery/13.png", height: getRandomHeight(200, 400), alt: "" },
    { src: "/gallery/14.png", height: getRandomHeight(300, 500), alt: "" },
    { src: "/gallery/15.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/17.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/18.png", height: getRandomHeight(300, 500), alt: "" },
    { src: "/gallery/19.png", height: getRandomHeight(200, 400), alt: "" },
    { src: "/gallery/20.png", height: getRandomHeight(200, 400), alt: "" },
    { src: "/gallery/21.png", height: getRandomHeight(300, 500), alt: "" },
    { src: "/gallery/22.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/23.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/24.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/25.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/26.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/27.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/28.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/29.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/30.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/31.png", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/32.jpg", height: getRandomHeight(400, 600), alt: "" },
    { src: "/gallery/33.jpg", height: getRandomHeight(400, 600), alt: "" },
  ];
  return (
    <Container>
      <Routing>
        <Routes>
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/home"} element={<Home />} />
          <Route exact path={"/contact"} element={<Contact />} />
          <Route
            exact
            path={"/gallery"}
            element={<Gallery images={images} />}
          />
          <Route path="/not-found" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Routes>
      </Routing>
    </Container>
  );
}

export default App;
