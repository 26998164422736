import React, { useContext } from 'react'
import { styled } from 'styled-components'
import { LanguageContext } from '../../contexts/Language/LanguageContext'
import {Responsiveness} from "../../responsive"

const Container = styled.div`
`

const Image= styled.img`
width:100%;
height:100%;
min-height:600px;
object-fit:cover;
opacity: 0.7;

${Responsiveness({minHeight:"300px"}, {minHeight:"300px"})}
`

const Wrapper = styled.div`
position:relative;
background-color: black;

`
const HeroText = styled.h1`

position: absolute;
top: 10vh;
left: 0;
width:100%;
color: white;
font-size: 34px;
font-wight:600;
text-align: center;

${Responsiveness({fontSize:"12px", fontWeight:"200", width:"100%", textAlign:"center", top:"2vh"}, {fontSize:"13px", fontWeight:"300", top:"2.5vh", width:"100%", textAlign:"center"}, {fontSize:"26px", fontWeight:"450", top:"3vh", width:"100%", textAlign:"center"})};
`

const Carding = styled.div`
position: absolute;
bottom: 20px;
left: 4vh;
width:96%;
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-gap: 20px;
height: auto;
${Responsiveness({left:"0.75vh", width:"98%", padding:"1px", bottom: "5px", gridGap:"10px"},{left:"0.75vh", width:"98%", padding:"2px", bottom: "7px", gridGap:"10px"},{left:"2vh", width:"98%", padding:"4px", bottom: "10px", gridGap:"10px"})};
`

const CardWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 0px 16px; 
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  ${Responsiveness()}
`;

const CardImage = styled.img`
margin-top:2vh;  
object-fit: cover;
max-width: 100%;
max-height: 100%;

${Responsiveness({maxWidth:"70%", maxHeight:"70%"},{maxWidth:"70%", maxHeight:"70%"},{maxWidth:"85%", maxHeight:"85%"})};

`

const CardTitle = styled.h2`
  font-size: 26px;
  margin-bottom: 8px;
  font-weight:400;
margin-top: 8px;
text-align: center;
${Responsiveness({fontSize:"8px", fontWeight:"100"}, {fontSize:"9px", fontWeight:"150"}, {fontSize:"15px", fontWeight:"300"})};
`;

const CardContent = styled.p`
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
  ${Responsiveness({fontSize:"5px", fontWeight:"200"}, {fontSize:"6px", fontWeight:"250"}, {fontSize:"9px", fontWeight:"300"})};

`;


const Hero = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);
    let data;
    switch (language) {
        case "Deutsch":
        data={
            HeroText: "Das umfangreiche Produktportfolio umfasst Industriemittel",
            card1Title: "Rund um die Uhr Support",
            card1Content: '24/7 Support für Import, Export, Ein- und Verkauf, sowie Reperaturen ganz nach dem Motto "All Hands on Deck"',
            card2Title: "Problem Solving",
            card2Content: "Wir lösen Ihre Probleme, egal ob bei Werkzeugmaschinen oder in Ihrer Produktion. Wir sind immer für Sie da.",
            card3Title: "Global aber Lokal",
            card3Content: "Uns zeichnet unter anderem eine Vielfalt von Standorten auf der ganzen Welt aus, wodurch wir für unsere Kunden jederzeit und überall zur Verfügung stehen.",
            card4Title: "Erfahrung + Fachkentniss",
            card4Content: 'Durch unsere jahrelange Erfahrung in den verschiedensten Projekten mit den unterschiedlichsten Unternehmen haben wir uns ein gewisses "Know-How" angeeignet, auf das unser Team sehr stolz ist. ',
        }
        break;
        default:
        data={
            HeroText: "The extensive product portfolio includes industrial resources",
            card1Title: "24/7 support",
            card1Content: '24/7 support for import, export, purchase and sale, as well as repairs according to the motto "All Hands on Deck"',
            card2Title: "Problem Solving",
            card2Content: "We solve your problems, whether with machine tools or in your production. We are always there for you.",
            card3Title: "Global but local",
            card3Content: "Among other things, we are characterized by a variety of locations around the world, which means that we are available to our customers anytime and anywhere.",
            card4Title: "Experience + expertise",
            card4Content: 'Through our many years of experience in a wide variety of projects with a wide variety of companies, we have acquired a certain "know-how" of which our team is very proud.',
        }
        break;
    }
  return (
    <Container>
        <Wrapper>
            <Image src='/lin.jpg'/>
            <HeroText>
               {data.HeroText}
            </HeroText>
            <Carding>
                <CardWrapper>
                    <CardImage src='/support.png'/>
                    <CardTitle>{data.card1Title}</CardTitle>
                    <CardContent>{data.card1Content}</CardContent>
                </CardWrapper>
                <CardWrapper>
                    <CardImage src='/ps.png'/>
                    <CardTitle>{data.card2Title}</CardTitle>
                    <CardContent>{data.card2Content}</CardContent>

                </CardWrapper>
                <CardWrapper>
                    <CardImage src='/global.png'/>
                    <CardTitle>{data.card3Title}</CardTitle>
                    <CardContent>{data.card3Content}</CardContent>

                </CardWrapper>
                <CardWrapper>
                    <CardImage src='/bs.png'/>
                    <CardTitle>{data.card4Title}</CardTitle>
                    <CardContent>{data.card4Content}</CardContent>

                </CardWrapper>
            </Carding>
        </Wrapper>
    </Container>
  )
}

export default Hero