import React, { useContext } from 'react';
import styled from 'styled-components';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import ContactComponent from '../../components/ContactComponent/ContactComponent';
import { Helmet } from 'react-helmet';

import { LanguageContext } from '../../contexts/Language/LanguageContext';

const Container = styled.div`
height: 100vh;
min-height: 100vh;
display: flex;
flex-direction: column;
`
const Contact = () => {
    const { language } = useContext(LanguageContext);
  let data;
  switch (language) {
    case "Deutsch":
      data={
        contact:"Kontakt"
      }
      break;
    default:
      data={
        contact:"Contact"
      }
      break;
  }
  return (
    <Container>
        <Helmet>
                <title>{data.contact}</title>
                <meta name="description" content="This is contact page of nb industries." />
                <meta name="robots" content="index, follow" />
        </Helmet>
        <Navbar />

        <ContactComponent />
        <Footer />
    </Container>
  )
}

export default Contact