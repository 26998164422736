
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.5);
  }
`;

const Zoom = (props) => {


  return (
    <Container>
      <Image
        src={props.img}
        alt="Zoomed Image"
      />
    </Container>
  );
};

export default Zoom;