import React, { useContext, useState } from 'react'
import { styled } from 'styled-components';
import { LanguageContext } from '../../contexts/Language/LanguageContext';
import { Responsiveness } from '../../responsive';

const Container = styled.div`

`

const Grid = styled.div`
display: grid;
  grid-template-columns: repeat(3, 1fr); 
  ${Responsiveness({gridTemplateColumns:"repeat(2, 1fr)"},{gridTemplateColumns:"repeat(2, 1fr)"},{gridTemplateColumns:"repeat(2, 1fr)"})}
`

const ContactForm = styled.form`
`

const ContactFormContainer = styled.div`
background-color: #f2f2f2;
`

const Input = styled.input`
background-color: #f2f2f2;
width: 80%;
margin: 2rem;
padding: 12px 20px;
box-sizing: border-box;
border: none;
margin-left: 1rem;
border-bottom: 2px solid red;
`

const TextArea = styled.textarea`
max-height:"300px";
max-width:"80%";
background-color: #f2f2f2;
width: 80%;
padding: 12px 20px;
box-sizing: border-box;
border: none;
margin-left: 1rem;
border-bottom: 2px solid red;
resize: none;
`

const Button = styled.button`
display: inline-block;
padding: 15px 25px;
margin-top: 2rem;
font-size: 24px;
cursor: pointer;
text-align: center;
text-decoration: none;
margin-left: 2rem;
outline: none;
color: white;
background-color: #4CAF50;
border: none;
border-radius: 15px;
box-shadow: 0 9px #999;
margin-bottom: 2rem;
${Responsiveness({fontSize:"12px"},{fontSize:"13px"},{fontSize:"16px"})}
`

const ContactInfoContainer = styled.div`

`

const ContactInfoTitle = styled.h2`
padding-left: 2rem;
alignment-baseline: central;
padding-top: 2rem;
color: ${(props)=>props.color};

${Responsiveness({fontSize:"14px"},{fontSize:"15px"},{fontSize:"18px"})}
`
const ContactInfoText = styled.h4`
padding-left: 2rem;
alignment-baseline: central;
${Responsiveness({fontSize:"12px"},{fontSize:"13px"},{fontSize:"16px"})}
`

const Image = styled.img`
width: 100%;
height: 100%;
max-width: 100%;
max-height: 100%;
object-fit: cover;

${Responsiveness({display:"none"},{display:"none"},{display:"none"})}
`

const ContactComponent = () => {
    const { language} = useContext(LanguageContext);
  let data;
  switch (language) {
    case "Deutsch":
      data={
        ln: "Bestätigung",
        contact: "Kontakt",
        address: "Hauptstandort",
        service: "unsere services",
        first: "✔ Beratung",
        second: "✔ Global Import und Export",
        third: "✔ An/Verkauf",
        fourth: "✔ Produktionmittel und Werkzeugmaschinen",
        country: "Deutschland",
        naming:"Nach und Vorname",
        emailing:"Email Adresse",
        numbering:"Tel Nr. (BSP +49 6984848)",
        messaging: "Nachricht",
      }
      break;
    default:
      data={
        ln: "Submit",
        contact: "Contact",
        address: "Main Location",
        service: "Our Services",
        first: "✔ Advice",
        second: "✔ Global import and export",
        third: "✔ Buy/Sell",
        fourth: "✔ Means of production and machine tools",
        country:"Germany",
        naming:"Name",
        emailing:"Email Adress",
        numbering:"Tel Nr. (BSP +49 6984848)",
        messaging: "Message",
      }
      break;
  }


  const [inputField , setInputField] = useState({
    first_name: '',
    message: '',
    tel: '',
    email: ''
})

  const inputsHandler = (e) =>{
    setInputField( {...inputField, [e.target.name]: e.target.value} )
    console.log(inputField)
  }

  const handleSubmit = (e) =>{
    console.log(e.target.reportValidity)
    if(e.target.checkValidity()){ 

        console.log(e)
        console.log("noice")
        let kek = inputField.message+":::email:::"+inputField.email+":::tel:::"+inputField.tel;
        console.log(kek);
        let mail = "mailto: info@nb-industry.com?subject="+inputField.first_name+"&body="+kek
        window.location = mail;
    }

  }

  return (
    <Container>
        <Grid>
            <ContactFormContainer>
                <ContactForm onSubmit={e => handleSubmit(e)}>
                    <Input type="text" name="first_name" onChange={inputsHandler} placeholder={data.naming}  required value={inputField.first_name}/>
                    <br/>
                    <Input type="text" name="email" onChange={inputsHandler} placeholder={data.emailing}  required value={inputField.email}/>
                    <br/>
                    <Input type="tel" name="tel" pattern='\+?\d{0,3}[\s\(\-]?([0-9]{2,3})[\s\)\-]?([\s\-]?)([0-9]{3})[\s\-]?([0-9]{2})[\s\-]?([0-9]{2})' onChange={inputsHandler} placeholder={data.numbering} required value={inputField.tel}/>
                    <br/>
                    <TextArea rows={4} cols={50} onChange={inputsHandler} required name='message' placeholder={data.messaging} value={inputField.message}/>
                    <br/>
                    <Button type='submit'>{data.ln}</Button>
                </ContactForm>
            </ContactFormContainer>
            <ContactInfoContainer>
              <ContactInfoTitle color='#37638d'>{data.contact}</ContactInfoTitle>
              <ContactInfoText>Tel:  +49 ​6021 326 8139</ContactInfoText>
              <ContactInfoText>Fax: +49 6021 326 8140</ContactInfoText>
              <ContactInfoTitle color='#90b9e1'>{data.address}</ContactInfoTitle>
              <ContactInfoText>Bodelschwinghstraße 14,</ContactInfoText>
              <ContactInfoText>63739 Aschaffenburg</ContactInfoText>
              <ContactInfoText>Bayern, {data.country}</ContactInfoText>
              <ContactInfoTitle color='#bdd6ee'>{data.service}</ContactInfoTitle>
              <ContactInfoText>{data.first}</ContactInfoText>
              <ContactInfoText>{data.second}</ContactInfoText>
              <ContactInfoText>{data.third}</ContactInfoText>
              <ContactInfoText>{data.fourth}</ContactInfoText>
            </ContactInfoContainer>
            <Image src='sed.png'/>
        </Grid>
    </Container>
  )
}

export default ContactComponent