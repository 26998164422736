import React, { useContext } from 'react'
import { LanguageContext } from '../../contexts/Language/LanguageContext';
import { styled } from 'styled-components';
import { Responsiveness } from '../../responsive';

const Container = styled.div`
`
const ImageContainer = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  min-height: 400px;
  object-fit: cover;
  ${Responsiveness({minHeight: "250px"},{minHeight: "300px"},{minHeight: "350px"})}
`;

const TextOverlay = styled.h4`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 33px;
  font-weight: bold;
  text-align: center;
  ${Responsiveness({fontSize:"12px"},{fontSize:"14px"},{fontSize:"19px"})}

`;
const PreEnd = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);
    let data;
    switch (language) {
      case "Deutsch":
        data={
            text: "Wo sich Fachkentnis und Erfahrung treffen"
        }
        break;
      default:
        data={
            text: "Where expertise and experience meet"
        }
        break;
    }
  return (
    <Container>
        <ImageContainer>
            <Image src="engine.jpg" alt="Image" />
            <TextOverlay>
                {data.text}
            </TextOverlay>
        </ImageContainer>
    </Container>
  )
}

export default PreEnd