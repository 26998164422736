import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";

import { Responsiveness } from "../../responsive";
import { LanguageContext } from "../../contexts/Language/LanguageContext";
const Image = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const IMG = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`;

const ModalContainer = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  overflow: auto;
`;

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0);
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: white;
  font-size: 36px;
`;

const Title = styled.h3`
  font-size: 36px;
  color: white;
  ${Responsiveness(
    { fontSize: "18px" },
    { fontSize: "18px" },
    { fontSize: "18px" }
  )}
`;
const Text = styled.h5`
  font-size: 28px;
  color: white;

  ${Responsiveness(
    { fontSize: "15px" },
    { fontSize: "15px" },
    { fontSize: "15px" }
  )}
`;
const Container = styled.div`
  background: #394d60;
`;
const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;
const Wrapper = styled.div`
  display: flex;
  ${Responsiveness(
    { flexDirection: "column" },
    { flexDirection: "column" },
    { flexDirection: "column" }
  )}
`;

const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  ${Responsiveness(
    { gridTemplateColumns: "repeat(1, 1fr)" },
    { gridTemplateColumns: "repeat(1, 1fr)" },
    { gridTemplateColumns: "repeat(1, 1fr)" }
  )}
`;
const Video = styled.video`
  width: auto;
  max-height: 500px;
  justify-content: center;
  align-items: flex-end;
`;

const Gallery = ({ images }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    document.addEventListener("click", closeModal);
    return () => {
      document.removeEventListener("click", closeModal);
    };
  }, []);
  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalOpen(false);
  };
  const { language } = useContext(LanguageContext);
  let data;
  switch (language) {
    case "Deutsch":
      data = {
        title: "Unsere laufenden Systeme/Dienstleistungen",
        text: "Dank unserer geschätzten Kunden haben wir eine umfangreiche Präsentation der von uns erbrachten Dienstleistungen.",
      };
      break;
    default:
      data = {
        title: "Our Systems/Services In Work",
        text: "Thanks to our valued customers, we have an extensive showcase of the services we've provided to them.",
      };
      break;
  }
  return (
    <Container>
      <Helmet>
        <title>Testimonial</title>
        <meta name="description" content="This is gallery of nb industries." />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <Navbar />
      <Wrapper>
        <Right>
          <Title>{data.title}</Title>
          <Text>{data.text}</Text>
        </Right>
        <Left>
          <Video autoPlay loop playsInline controls>
            <source src="16.mp4" type="video/mp4" />
            <source src="16.webm" type="video/webm" />
            <source src="16.ogv" type="video/ogg" />
          </Video>
        </Left>
      </Wrapper>
      <GalleryContainer>
        {images.map((image, index) => (
          <Image
            key={index}
            src={image.src}
            loading="lazy"
            alt={`Image ${index}`}
            onClick={(e) => {
              e.stopPropagation();
              openModal(image);
            }}
          />
        ))}
      </GalleryContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            placeItems: "center",
          }}
        >
          <Video
            autoPlay
            muted
            loop
            playsInline
            controls
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <source src="34.mp4" type="video/mp4" />
          </Video>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            placeItems: "center",
          }}
        >
          <Video
            autoPlay
            muted
            loop
            playsInline
            controls
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <source src="35.mp4" type="video/mp4" />
          </Video>
        </div>
      </div>
      <ModalContainer isOpen={modalOpen}>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        <ModalContent>
          {selectedImage && <IMG src={selectedImage.src} alt="Modal" />}
        </ModalContent>
      </ModalContainer>
      <Footer />
    </Container>
  );
};
export default Gallery;
