import React, { useContext } from 'react'
import { LanguageContext } from '../../contexts/Language/LanguageContext';
import { styled } from 'styled-components';
import { Responsiveness } from '../../responsive';
import Zoom from '../Zoom/Zoom';
const Container = styled.div`
background-color: #394d60;
padding-bottom:20px;
padding-left: 20px;
padding-right: 20px
`

const Text = styled.h1`

font-size: 40px;
color:white;
padding: 50px;
text-align: center;
text-decoration: underline;
font-weight: 600;
${Responsiveness({fontWeight:"200", fontSize:"12px"},{fontWeight:"250", fontSize:"19px"})}
`

const Grid = styled.div`
display: grid;
grid-gap: 20px;

  grid-template-columns: repeat(4, 1fr); 
  ${Responsiveness({gridTemplateColumns:"repeat(2, 1fr)"},{gridTemplateColumns:"repeat(2, 1fr)"})}
`
const Team = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);
  let data;
  switch (language) {
    case "Deutsch":
      data={
        title: "UNSER TEAM",
      }
      break;
    default:
      data={
        title: "Our Team",
      }
      break;
  }
  return (
    <Container>
        <Text>
            {data.title}
        </Text>
        <Grid>
            <Zoom img="/bano_re.jpg"/>
            <Zoom img="/agha_re.jpg"/>
            <Zoom img="/khanom_re.jpg"/>
            <Zoom img="/unnamed_re.jpg"/>
        </Grid>
    </Container>
  )
}

export default Team