import React, { useContext, useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { LanguageContext } from '../../contexts/Language/LanguageContext';
import {Responsiveness} from "../../responsive"
const Container = styled.div`
height: 100px;

margin-bottom: 20px;
${Responsiveness({height: "50px", marginBottom: "10px"}, {height: "62px", marginBottom: "10px"}, {height: "75px", marginBottom: "10px"})};

`
const Wrapper = styled.div`
padding: 10px 20px;
display: flex;
align-items: center;
justify-content: space-between;

background-color: #afcdea;
${Responsiveness({padding: "5px 5px"}, {padding: "5px 7px"}, {padding: "5px 10px"})};
`

const Left = styled.div`
flex:1;
display: flex;
align-items: center;

`
const Change = styled.span`
font-size: 18px;
color: white;
${Responsiveness({fontSize: "12px"}, {fontSize: "13px"}, {fontSize: "15px"})};`

const Language = styled.span`
font-size: 18px;
cursor: pointer;
border-radius:50%;
color: white;
&:hover{
  background-color:rgb(0,0,0,0.2);
};

${Responsiveness({fontSize: "12px"}, {fontSize: "13px"}, {fontSize: "15px"})};
`
const Image = styled.img`
object-fit: cover;
height:100px;
${Responsiveness({height: "50px"}, {height: "62px"}, {height: "75px"})};

`

const Center = styled.div`
flex:1;
text-align: center;
`

const Right = styled.div`
flex:1;
display: flex;
align-items: center;
justify-content: flex-end;
`

const NavLinks = styled.div`
  display: flex;
    align-items: center;
  ${Responsiveness({display: "none"}, {display: "none"}, {display: "none"})};
`;

const NavLink = styled.a`
  color: white;
  padding:10px 20px;
  text-decoration: none;
`;


const Dropdown = styled.div`
  z-index: 1;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #394d60;
  width: 100%;
  
  display:none;
  transition: opacity 0.3s ease-in-out, , visibility 0.3s ease-in-out;;
  
  opacity: ${({ open }) => (open ? "1" : "0")};
  
  padding: 0;
  ${({ open }) => (open ? Responsiveness({display: "flex"}, {display: "flex"}, {display: "flex"}) : Responsiveness({display: "none"}, {display: "none"}, {display: "none"}))}
  
`;

const DropdownButton = styled.span`
  cursor: pointer;
  padding: 5px 10px;
  display: none;
  ${Responsiveness({display: "flex"}, {display: "flex"}, {display: "flex"})};
`;

const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
`;
const LogoLink = styled.a`
  text-decoration: none;
`;
const Navbar = () => {
  const { language, toggleLanguage } = useContext(LanguageContext);
  
  const [dropdownOpen, setDropdownOpen] = useState(false);

  let data;
  switch (language) {
    case "Deutsch":
      data={
        ln:"DE",
        home: "Heim",
        gallery: "Galerie",
        contact: "Kontakt",
        change:"Sprache ändern:"
      }
      break;
    default:
      data={
        ln:"EN",
        home: "Home",
        gallery: "Gallery",
        contact: "Contact",
        change:"Change Language:"
      }
      break;
  }
  

  
  const toggleDropdown = (e) => {
    
    e.stopPropagation();
    setDropdownOpen((prevState) => !prevState);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);
  return (
    <Container>
        <Wrapper>
          <Left >
            <LogoLink href="/home">
              <Image src='/NB.png'/>
            </LogoLink>
          </Left>
          <Center>
          </Center>
          <Right>
            <NavLinks>
              <NavLink href="/home">{data.home}</NavLink>
              <NavLink href="/gallery">{data.gallery}</NavLink>
              <NavLink href="/contact">{data.contact}</NavLink>
              <Change>

                {data.change} 
              </Change>
            <Language onClick={toggleLanguage}>
            &nbsp;{data.ln}
            </Language>
          </NavLinks>
          
          <DropdownButton onClick={toggleDropdown}>☰</DropdownButton>
          <Dropdown open={dropdownOpen}>
            <DropdownContent>
              <NavLink href="/home">{data.home}</NavLink>
              <NavLink href="/gallery">{data.gallery}</NavLink>
              <NavLink href="/contact">{data.contact}</NavLink>
              
              <NavLink onClick={toggleLanguage}>
             
              {data.change} &nbsp;{data.ln}
              </NavLink>
            </DropdownContent>
          </Dropdown>
          </Right>
        </Wrapper>
    </Container>
  )
}

export default Navbar