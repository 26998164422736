import React, { useContext } from 'react'
import { styled } from 'styled-components'
import { LanguageContext } from '../../contexts/Language/LanguageContext'
import { Responsiveness } from '../../responsive'


const Container = styled.div`
background-color: black;
`
const TextOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  ${Responsiveness({fontSize:"12px"},{fontSize:"19px"})}

`;


const VideoContainer = styled.div`
  position: relative;
  background-color:black;
`;
const Title = styled.h4`

`
const Text = styled.h5`

`
const Video = styled.video`
  width: 100%;
  height: auto;
  opacity:0.6;
`;
const VideoPlayer = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);
  let data;
  switch (language) {
    case "Deutsch":
      data={
        title: "WERKZEUGMASCHINEN",
        text: "Diverse Werkzeugmaschinen. 24/7 Support. Fachkenntnis + Global Erfahrung"
      }
      break;
    default:
      data={
        title: "MACHINE TOOLS",
        text: "Various machine tools. 24/7 support. Expertise + Global Experience"
      }
      break;
  }
  return (

    <Container>
        <VideoContainer>
            <Video autoPlay loop muted playsInline>
              <source src="xd.mp4" type="video/mp4" />
              <source src="xd.webm" type="video/webm" />
              <source src="xd.ogv" type="video/ogg" />
            </Video>
            <TextOverlay>
                <Title>{data.title}</Title>
                <Text>{data.text}</Text>
            </TextOverlay>
        </VideoContainer>
    </Container>
  )
}

export default VideoPlayer