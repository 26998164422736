import React from 'react'
import styled from 'styled-components';
import Navbar from '../../components/Navbar/Navbar';
import Hero from '../../components/Hero/Hero';
import Footer from '../../components/Footer/Footer';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import CheckerCard from '../../components/CheckerCard/CheckerCard';
import Experties from '../../components/Experties/Experties';
import Team from '../../components/Team/Team';
import PreEnd from '../../components/PreEnd/PreEnd';

const Container = styled.div`

`
const Home = () => {
  
  return (
    <Container>
      <Navbar />
      <Hero />
      <VideoPlayer />
      <CheckerCard />
      <Experties />
      <Team />
      <PreEnd />
      <Footer />
    </Container>

  )
}

export default Home