import React, { useContext } from 'react'
import { LanguageContext } from '../../contexts/Language/LanguageContext';
import { styled } from 'styled-components';
import { Responsiveness } from '../../responsive';

const Container = styled.div`
background-color: black;
`
const Title = styled.h1`
text-align:center;
color: white;
`

const Text = styled.h2`
text-align:center;
color:white;
margin-top:20px;
`

const Checker = styled.div`
display: grid;
  grid-template-columns: repeat(3, 1fr); 
  ${Responsiveness({gridTemplateColumns:"repeat(3, 1fr)"},{gridTemplateColumns:"repeat(3, 1fr)"})}
`

const CheckerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`;

const CheckerTitle = styled.h1`
font-size: 26px;
text-align: center;
font-weight: 400;
${Responsiveness({fontWeight:"200", fontSize:"12px"},{fontWeight:"225", fontSize:"12px"},{fontWeight:"250", fontSize:"19px"})}
`
const CheckerText = styled.h3`
text-align: center;
margin:10px;
font-size: 22px;
font-weight: 200;
${Responsiveness({fontWeight:"100", fontSize:"10px"},{fontWeight:"125", fontSize:"10px"},{fontWeight:"150", fontSize:"17px"})}
`
const CheckerCard = () => {
    const { language, toggleLanguage } = useContext(LanguageContext);
  let data;
  switch (language) {
    case "Deutsch":
      data={
        bigTitle: "NB-Industries",
        bigText: "Erfahrener Zulieferer für Industriemaschinen aller Art. Hier werden Qualität und Fachkenntnis kombiniert.",
        smallTitle1: "Über Uns",
        smallText1: "Nb-industries ist ein Familienunternehmen mit Standorten in den Usa, UAE, China und Deutschland. Unser Betrieb hat Erfahrung in Maschinenbau, Mechatronik, Elektrotechnik und Industriemitteln. Zudem bringt er Fachkenntnisse und Erfahrungen, auf welche wir sehr Stolz sind.",
        smallTitle2: "Unsere Strategie",
        smallText2: `Wir beraten Sie persönlich und ausführlich zu allen Fragen rund um Ihren individuellen Bedarf in Sachen Werkzeugmaschinen. Gemäß unserem Motto "All hands on deck" sorgen wir stets dafür, dass Sie schnell die Gebrauchtmaschine finden, die für die speziellen Anforderungen ihrer Produktion am besten geeignet ist.`,
        smallTitle3: "Unsere Mission",
        smallText3: "Unsere Spezialisten für Werkzeugmaschinen kennen die spezifischen Anforderungen der verschiedensten Produktionsprozesse und haben stets den Überblick über die adäquaten Fertigungstechnologien auf der Höhe der Zeit. Mit ihrer hohen fachlichen Qualifikation, langjähriger Erfahrung und lösungsorientiertem Denken finden unsere Profis zuverlässig die geeignete Maschine für Ihren Bedarf.",
      }
      break;
    default:
      data={
        bigTitle: "NB-Industries",
        bigText: "Experienced supplier for industrial machines of all kinds. Quality and expertise are combined here.",
        smallTitle1: "About Us",
        smallText1: "Nb-industries is a family company with locations in the USA, UAE, China and Germany. Our company has experience in mechanical engineering, mechatronics, electrical engineering and industrial resources. We also bring expertise and experience that we are very proud of.",
        smallTitle2: "Our Strategy",
        smallText2: `We advise you personally and in detail on all questions relating to your individual needs in terms of machine tools. According to our motto "All hands on deck" we always make sure that you quickly find the used machine that is best suited for the special requirements of your production.`,
        smallTitle3: "Our Mission",
        smallText3: "Our specialists for machine tools know the specific requirements of the most diverse production processes and always have an overview of the appropriate, state-of-the-art manufacturing technologies. With their high professional qualifications, many years of experience and solution-oriented thinking, our professionals will reliably find the right machine for your needs.",
    }
      break;
  }
  return (
    <Container>
        <Title>
            {data.bigTitle}
        </Title>
        <Text>
            {data.bigText}
        </Text>
        <Checker>
            <CheckerItem>
                <CheckerTitle>
                    {data.smallTitle1}
                </CheckerTitle>
                <CheckerText>
                    {data.smallText1}
                </CheckerText>
            </CheckerItem>
            <CheckerItem>
                <Image src='image-asset.jpg'/>
            </CheckerItem>
            <CheckerItem>
                <CheckerTitle>
                    {data.smallTitle2}
                </CheckerTitle>
                <CheckerText>
                    {data.smallText2}
                </CheckerText>
            </CheckerItem>
            <CheckerItem>
                <Image src='isto.jpg'/>
            </CheckerItem>
            <CheckerItem>
                <CheckerTitle>
                    {data.smallTitle3}
                </CheckerTitle>
                <CheckerText>
                    {data.smallText3}
                </CheckerText>
            </CheckerItem>
            <CheckerItem>
                <Image src='common.jpg'/>
            </CheckerItem>
            
        </Checker>
    </Container>
  )
}

export default CheckerCard